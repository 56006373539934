<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();
  export let id
  export let value = null

  const onChange = (val) => {
    value = val
    dispatch('change')
  }
</script>

<div class="my-6">
  <p class="question md:text-lg max-w-lg lg:w-3/4"> 
    <slot></slot>
  </p>

  <div class="switch-field mt-4">
      
      <input on:change={() => onChange(true)} type="radio" name="{ id }" id="{ id }-oui" autocomplete="off"  />
      <label for="{ id }-oui">oui</label>

      <input on:change={() => onChange(false)} type="radio" name="{ id }" id="{ id }-non" autocomplete="off" />
      <label for="{ id }-non">non</label>

  </div>
</div>