import 'styles/tw'
import 'styles/custom'
import 'styles/tippy/light'
import 'styles/tippy/animations/shift-away.css';
import $ from 'jquery'
import Filters from './filters'
import autoComplete from "@tarekraafat/autocomplete.js";
import Certin from './svelte/Certin.svelte'
import Cookies from 'js-cookie'

window.jQuery = $

const break_xl = 1280

class Pyc {
    
    constructor(){
        if( $('.js-filters').length > 0 ){
            this.filters = new Filters()
        }
    }

    toggle(event, content){
        let $toggler = $(event.currentTarget)
        let $icon = $toggler.find('.icon')
        let $content = $(content)
        // console.log($content)
        if ($content.hasClass('hidden')){
            $content.removeClass('hidden')
            $icon.addClass('rotate-180 transform')
        }else{
            $content.addClass('hidden')
            $icon.removeClass('rotate-180 transform')
        }
    }
    
    toggle_antecedent(event, content){
        let $toggler = $(event.currentTarget)
        let $content = $(content)
        let $parent = $content.parent()
        $parent.remove( event.currentTarget )
        $parent.append($content)
        $content.removeClass('hidden')
        $toggler.addClass('hidden')
    }
    
    allow_cookies(bool){
        Cookies.set( 'rgpd', bool, { expires : 365 })
        window.location.reload()
        $('#rgpd').remove()
    }
    
}


window.addEventListener('load', function(event) {

    window.pyc = new Pyc()

    if( $('body.certin').length ){
        const certin = new Certin({
            target : document.getElementById('svelte-certin')
        })
    }
    
    let nav_open_xl = Cookies.get('nvxl') ? Boolean(parseInt(Cookies.get('nvxl'))) : window.innerWidth >= break_xl
    let nav_open = false
    
    const $body = $('body')
    const $overlay = $('#overlay')
    
    const $nav_toggler = $('#nav-toggler')
    const $main = $('#main-col-right')

    const $nav_sidebar = $('#nav-sidebar')

    init_search()
    

/* -------------------------------------------------------------------------- */
/*                                 LEXIQUE                                    */
/* -------------------------------------------------------------------------- */

    if( $('body.lexique').length ){
        
        function highlight() {
            $('.anchor').each( (i,el) => {
                const $el = $(el)
                if( $el.attr('id') === location.hash.split('#')[1] ){
                    $el.parent().parent().addClass('bg-green-200')
                }else{
                    $el.parent().parent().removeClass('bg-green-200')
                }
            })
        }
        highlight()
        window.addEventListener('hashchange', highlight)
    }

/* -------------------------------------------------------------------------- */
/*                                 OVERLAY                                    */
/* -------------------------------------------------------------------------- */

    if( $('#popup-sondage').length ){
        
        const $popup_wrapper = $('#popup-wrapper')
        const $popup_overlay = $('#popup-overlay')
        const $popup_sondage = $('#popup-sondage')

        if( parseInt(Cookies.get('ppsdg')) !== 1 ){
            
            const close_popup = () => {
                setTimeout(() => {
                    $popup_wrapper.remove()
                }, 1000)
                $popup_wrapper.addClass('pointer-events-none')
                $popup_overlay.removeClass('opacity-75').addClass('opacity-0')
                $popup_sondage.removeClass('opacity-100 translate-y-0').addClass('opacity-0 translate-y-8')
            }
            
            const show_popup = () => {
                $popup_wrapper.removeClass('pointer-events-none hidden').addClass('flex')
                setTimeout(() => {
                    $popup_overlay.addClass('opacity-75')
                    $popup_sondage.removeClass('translate-y-8 opacity-0').addClass('opacity-100 translate-y-0')
                }, 10)
            }

            const $sondage_done = $('#sondage-done')
            $sondage_done.on('click', e => {
                Cookies.set('ppsdg', 1, { expires : 365 })
                close_popup()
            })
            
            const $sondage_nope = $('#sondage-nope')
            $sondage_nope.on('click', e => {
                Cookies.set('ppsdg', 1, { expires : 365 })
                close_popup()
            })
            
            const $sondage_close = $('#sondage-close')
            $sondage_close.on('click', e => {
                close_popup()
                Cookies.set('ppsdg', 1, { expires : 1 })
            })
            $popup_overlay.on('click', e => {
                close_popup()
                Cookies.set('ppsdg', 1, { expires : 1 })
            })
            
            const $sondage_go = $('#sondage-go')
            $sondage_go.on('click', e => {
                if( window._paq ){
                    window._paq.push(['trackEvent', 'Popup Events', 'click vers sondage'])
                    console.log('pushed')
                }
                Cookies.set('ppsdg', 1, { expires : 1 })
                window.open( $sondage_go.data('url'), '_blank').focus();
                close_popup()
            })
            setTimeout( () => {
                show_popup()
            }, 4000)
        }else{
            $popup_wrapper.remove()
        }
    }

/* -------------------------------------------------------------------------- */
/*                                 OVERLAY                                    */
/* -------------------------------------------------------------------------- */

    function show_overlay () {
        $overlay.addClass('opacity-80 pointer-events-all')
        $overlay.removeClass('opacity-0 pointer-events-none')
    }

    function hide_overlay () {
        $overlay.removeClass('opacity-80 pointer-events-all')
        $overlay.addClass('opacity-0 pointer-events-none')
    }

/* -------------------------------------------------------------------------- */
/*                                     NAV                                    */
/* -------------------------------------------------------------------------- */

    

    $nav_toggler.on('click', () => {
        if( window.innerWidth >= break_xl){
            if( nav_open_xl ){
                $nav_sidebar.addClass('xl:-translate-x-72')
                $nav_sidebar.removeClass('xl:translate-x-0')
                $main.removeClass('xl:pl-72')
            }else{
                $nav_sidebar.addClass('xl:translate-x-0')
                $nav_sidebar.removeClass('xl:-translate-x-72')
                $main.addClass('xl:pl-72')
            }
            nav_open_xl = !nav_open_xl
            Cookies.set('nvxl', nav_open_xl ? 1 : 0)
        }else{
            if( nav_open ){
                $nav_sidebar.addClass('-translate-x-72')
                $nav_sidebar.removeClass('translate-x-0')
                hide_overlay()
            }else{
                $nav_sidebar.addClass('translate-x-0')
                $nav_sidebar.removeClass('-translate-x-72')
                show_overlay()
            }
            nav_open = !nav_open
        }
    })

    window.addEventListener('resize', () => {
        if( window.innerWidth >= break_xl){
            // Close nav < break_xl
            $nav_sidebar.addClass('-translate-x-72')
            $nav_sidebar.removeClass('translate-x-0')
            hide_overlay()
            nav_open = false
        }
    })

/* -------------------------------------------------------------------------- */
/*                                  SEARCH                                    */
/* -------------------------------------------------------------------------- */

    function init_search () {
        let currentSelection
        const $form = $('#js-search-group')
        const $input = $('#autoComplete')
        const url = $form.attr('action')
        const config = {
            placeHolder: "Rechercher...",
            data: {
                src: async (query) => {
                    let results = await $.get(url + '.json?q=' + query)
                    return results
                },
                keys : ['title'],
                cache: false,
            },
            resultsList: {
                element: (list, data) => {
                  const info = document.createElement("p");
                  if (data.results.length === 0) {
                    info.innerHTML = '<div class="hidden sm:block px-3 py-3 text-xs border-t border-indigo-100 ">Aucun résultat</div>';
                  }else{
                    info.innerHTML = `<div class="hidden sm:block px-3 py-3 text-xs">${data.results.length} résultat(s)</div>`
                  }
                  list.prepend(info);
                },
                noResults: true,
                maxResults: 10,
                tabSelect: true
            },
            resultItem: {
                element: (item, data) => {
                    // console.log(data)
                    item.innerHTML = `
                        <div class="flex justify-between items-center">
                            <span class="text-xs md:text-sm">${data.match}</span>
                            <span class="hidden md:block uppercase text-xs text-gray-300">${data.value.type}</span>
                        </div>
                    `
                },
                highlight: {
                    render: true
                }
            },
            submit: () => {},
        }

        const autoCompleteJS = new autoComplete( config );
        
        $input.on('focus', e => {
            show_overlay()
        })

        $input.on('blur', e => {
            hide_overlay()
        })

        document.querySelector("#autoComplete").addEventListener("navigate", (event) => {
            currentSelection = event.detail.selection
            $input.val(event.detail.selection.value.title) 
        });

        document.querySelector("#autoComplete").addEventListener("selection", function (event) {
            // "event.detail" carries the autoComplete.js "feedback" object
            window.location.href = event.detail.selection.value.url
        });

    }
    
/* -------------------------------------------------------------------------- */
/*                                   POPOVER                                  */
/* -------------------------------------------------------------------------- */

    $('.dicosemio').each( (i,el) => {
        const $el = $(el)
        // const tippy/light = $(el)
        tippy( $el[0], {
            content: $el.data('content'),
            theme: 'light',
            animation: 'shift-away'
        });
    })
    

/* -------------------------------------------------------------------------- */
/*                                    PRINT                                   */
/* -------------------------------------------------------------------------- */
    
    window.onbeforeprint = function() { 
        $(document).scrollTop(0)
    }
    
    
/* -------------------------------------------------------------------------- */
/*                         TOGGLE ADRESSE FORM SSC                            */
/* -------------------------------------------------------------------------- */

    $('#patient_homeless').on('change', function () {
        // console.log($(this).prop('checked'))
        if( $(this).prop('checked') ){
            $('#adresse-wrap').addClass('hidden')
            $('#adresse-wrap').find('input[type=text]').each( (i, el) => $(el).prop('required', false) )
        }else{
            $('#adresse-wrap').removeClass('hidden')
            $('#adresse-wrap').find('input[type=text]').each( (i, el) => $(el).prop('required', true) )
        }
    
    })

/* -------------------------------------------------------------------------- */
/*                         TOGGLE RISQUE FORM URGENCE                         */
/* -------------------------------------------------------------------------- */

    $('[name=urgence-q-0]').on('click', function () {
        
        if( $(this).val() === "on" ){
            $('.risque-s').removeClass('hidden')
        }else{
            // $('.risque-s input[value="on"]').prop('checked', false)
            // $('.risque-s input[value="off"]').prop('checked', true)
            $('.risque-s').addClass('hidden')
        }
    
    })
    
/* -------------------------------------------------------------------------- */
/*                                   CONTACT                                  */
/* -------------------------------------------------------------------------- */

    var $form = $('#contact-form');

    if( $form.length ){
        var form = $form[0];

        const on_error = (errors) => {
            $('#response_error').html('')
            errors.forEach( e => {
                $('#response_error').append(`${e}<br/>`)
            })
            $('#response_error').removeClass('opacity-0')
        }

        const on_success = () => {
            $form.find('button').remove()
            $form.find('#contact-success').removeClass('hidden')
        }
        
        // var message = document.getElementById('response_message');
        var fields = {};

        form.querySelectorAll('[name]').forEach(function (field) {
            fields[field.name] = field;
        });
    

        // Displays all error messages and adds 'error' classes to the form fields with
        // failed validation.
        var handleError = function (response) {
            
            var errors = [];
            
            for (var key in response) {
                if (!response.hasOwnProperty(key)) continue;
                if (fields.hasOwnProperty(key)) fields[key].classList.add('error');
                Array.prototype.push.apply(errors, response[key]);
            }
            
            on_error(errors)
        
        }

        var onload = (e) => {
            if (e.target.status === 200) {
                on_success()
            } else {
                handleError(JSON.parse(e.target.response));
            }
        };

        var submit = function (e) {
            e.preventDefault();
            var request = new XMLHttpRequest();
            request.open('POST', e.target.action);
            request.onload = onload;
            request.send(new FormData(e.target));
            // Remove all 'error' classes of a possible previously failed validation.
            for (var key in fields) {
                if (!fields.hasOwnProperty(key)) continue;
                fields[key].classList.remove('error');
            }
            $('#response_error').addClass('opacity-0')
            
        };
    
        form.addEventListener('submit', submit);
    }


/* -------------------------------------------------------------------------- */
/*                                  ANNUAIRE                                  */
/* -------------------------------------------------------------------------- */

    if( $body.hasClass('annuaire') ){
        
        // const annuaire = new Annuaire()
        
    }

})