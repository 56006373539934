import $ from 'jquery'

export default class Filters {

    constructor(){
        
        this.$checkboxes = $('.js-filters input[type=checkbox]')
        this.item_selector = $('.js-filters').data('selector')

        if( window.location.hash ){
            let categories = this._apply_filters([window.location.hash.replace('#','')])
            this._filter(categories)
        }

        this.$checkboxes.on('click', e => {
            let categories = this.$checkboxes.toArray().filter( input => input.checked ).map( input => input.value )
            this._filter(categories)
        })

    }

    toggle (event) {
        let $toggler = $(event.currentTarget)
        let $icon = $toggler.find('.icon')
        let $filters = $('.js-filters')
        if ($filters.hasClass('hidden')){
            $filters.removeClass('hidden')
            $icon.addClass('rotate-180 transform')
        }else{
            $filters.addClass('hidden')
            $icon.removeClass('rotate-180 transform')
        }
    }
    
    none(){
        this._filter(this._apply_filters([]))
    }

    all(){
        this._filter(this._apply_filters())
    }

    _apply_filters ( arr ){
        this.$checkboxes.toArray().forEach( input => input.checked = ( arr === undefined || arr.includes(input.value)))
        return this.$checkboxes.toArray().filter( input => input.checked ).map( input => input.value )
    }
    
    _filter(categories){
        if(categories.length === 0){
            $(this.item_selector).removeClass('hidden')
        }else{
            $(this.item_selector).each( (i, el) => {
                let $fiche = $(el)
                if( $fiche.data('cat').split(' ').some( item => categories.includes(item)) ){
                    $fiche.removeClass('hidden')
                }else{
                    $fiche.addClass('hidden')
                }
            })
        }
    }
}
