<script>
  import Question from './Question.svelte'
  import Stop from './Stop.svelte'

  // const certificat_url = "http://localhost/soins-psychiatrique-sans-consentement/certin/certificat"
  const certificat_url = "https://www.psychiaclic.fr/soins-psychiatriques-sans-consentement/certin/certificat"

  let has_trouble = null
  let need_supervision = null
  let is_confined = null
  let is_danger = null
  let public_order_trouble = null
  let people_safety = null
  let impossible_consent = null
  let third_party = null
  let emmergency = null
  let peril = null
  let has_certificat = null

  let questions = [
    has_trouble,
    need_supervision,
    is_confined,
    is_danger,
    public_order_trouble,
    people_safety,
    impossible_consent,
    third_party,
    emmergency,
    peril,
    has_certificat
  ]

  const reset_from_is_confined = () => {
    is_confined = null
    is_danger = null
    public_order_trouble = null
    people_safety = null
    impossible_consent = null
    third_party = null
    emmergency = null
    peril = null
    has_certificat = null
  }

  const reset_from_is_danger = () => {
    is_danger = null
    public_order_trouble = null
    people_safety = null
    impossible_consent = null
    third_party = null
    emmergency = null
    peril = null
    has_certificat = null
  }

  const reset_from_people_safety = () => {
    people_safety = null
    impossible_consent = null
    third_party = null
    emmergency = null
    peril = null
    has_certificat = null
  }

  const reset_from_emmergency = () => {
    emmergency = null
    peril = null
    has_certificat = null
  }
  const reset_from_has_certificat = () => {
    has_certificat = null
  }
    
</script>

{#if !(has_trouble && need_supervision) }

  <Question on:change={reset_from_is_confined} bind:value={has_trouble} id="a-1">
    Le patient présente-t-il des troubles psychiatriques ?
  </Question>

  <Question on:change={reset_from_is_confined} bind:value={need_supervision} id="a-2">
    Ces troubles nécessitent-il des soins immédiats assortis d’une surveillance constante en milieu hospitalier ?
  </Question>

{/if}

{#if has_trouble && need_supervision && (impossible_consent === null || impossible_consent === false) }
  
  <Question on:change={reset_from_is_danger} bind:value={is_confined} id="b-1">
    Le patient est-il détenu ?
  </Question>
  
  {#if is_confined === true }
    
    <Question on:change={reset_from_people_safety} bind:value={is_danger} id="c-1">
      Le patient présente t'il un danger immédiat pour lui même ou pour autrui ?
    </Question>
    
    {#if is_danger === false }

      <Stop />
    
    {:else if is_danger === true}
      
      <a class="btn-large-green" href="{certificat_url}?type=sdre_detenu&step=1">
        Mesure de SDRE / Personne détenue
      </a>

    {/if}
    
  {:else if is_confined === false }

    <Question on:change={reset_from_people_safety} bind:value={public_order_trouble} id="c-2">
       Le patient présente t'il un trouble à l'ordre public ou compromet t'il la sureté des personnes ?
    </Question>

    {#if public_order_trouble === true}

      <Question on:change={reset_from_emmergency} bind:value={people_safety} id="d-1">
        Présente t'il un danger imminent pour la sureté des personnes ?
      </Question>
      
      {#if people_safety === true}
        
        <a class="btn-large-green" href="{certificat_url}?type=sdre_danger&step=1">
          Mesure SDRE danger imminent pour la sureté des personnes
        </a>
        

      {:else if people_safety === false}
        
        <a class="btn-large-green" href="{certificat_url}?type=sdre&step=1">
          Mesure de SDRE
        </a>

      {/if}

    {:else if public_order_trouble === false}
      
      <Question on:change={reset_from_emmergency} bind:value={impossible_consent} id="d-3">
        Les troubles présentés par le patient rendent-ils impossibles son consentement ?
      </Question>
      
      {#if impossible_consent === false}
        <Stop />
      {/if}

    {/if}
    
  {/if}

{:else if (has_trouble === false || need_supervision === false) && (has_trouble !== null && need_supervision !== null)}
    <Stop />
{/if}


  <!--  -->


  {#if impossible_consent === true }

    <Question on:change={reset_from_emmergency} bind:value={third_party} id="d-2">
      Présence d'un tiers ?
    </Question>
    
    {#if third_party === true}
    
      <Question on:change={reset_from_has_certificat} bind:value={emmergency} id="e-2">
        Caractère d'urgence ?
      </Question>

      {#if emmergency === true}
        
        <a class="btn-large-green" href="{certificat_url}?type=sdt_urgence&step=1">
          SDT mesure d'urgence
        </a>
        
      {:else if emmergency === false}

        <Question bind:value={has_certificat} id="f-1">
          Un premier certificat a t'il été rédigé ? ?
        </Question>
        
        {#if has_certificat === true}
          
          <a class="btn-large-green" href="{certificat_url}?type=sdt2&step=1">
            Mesure de SDT / 2ème certificat
          </a>

        {:else if has_certificat === false}
          
          <a class="btn-large-green" href="{certificat_url}?type=sdt1&step=1">
            Mesure de SDT / 1er certificat
          </a>
        {/if}

      {/if}
    
    {:else if third_party === false}
      
      <Question on:change={reset_from_has_certificat} bind:value={peril} id="e-2">
        Péril imminent ?
      </Question>
      
      {#if peril === true}
        
        <!-- ok -->
        <a class="btn-large-green" href="{certificat_url}?type=sppi&step=1">
          Mesure de soins psychiatriques en cas de péril imminent
        </a>
      
      {:else if peril === false}
        <Stop />
      {/if}
      
    {/if}
  
  {/if}


  <!--  -->